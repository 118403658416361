body {
  font-family: Arial, sans-serif;
  background-color: #1B1D28;
  color: white;
}

.App {
  /* max-width: 1200px; */
  margin: 0 auto;
  /* padding: 20px; */
}

header {
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 10px 0;
  /* border-bottom: 1px solid #444; */
}

header nav a {
  color: #00f;
  margin-right: 15px;
}

header .notification {
  /* background-color: #ff0000;
  color: white; */
  padding: 5px 10px;
  border-radius: 5px;
  margin-left: 10px;
}

main {
  padding: 10px 20px;
  margin: auto;
}

.controls {
  display: flex;
  align-items: center;
  margin-bottom: 20px;
}

.controls input {
  padding: 10px;
  margin-right: 10px;
}

.controls select {
  padding: 10px;
}

footer {
  text-align: center;
  padding: 20px 0;
  border-top: 1px solid #444;
}

.image {
  width: 128px; /* Set the width of the image */
  height: 128px; /* Maintain the aspect ratio */
  margin: 20px 0;
}

.big-image {
  width: 228px; /* Set the width of the image */
  height: 228px; /* Maintain the aspect ratio */
  margin: 20px 0;
}

.header-nav {
  display: flex;
  align-items: center;
  justify-content: flex-end; /* Align items to the end of the flex container */
}

@media (max-width: 768px) { /* Adjust the breakpoint as needed */
  .header-nav {
    flex-direction: column; /* Stack items vertically on mobile */
    align-items: flex-start; /* Align items to the start of the flex container */
  }
}


.formButton {
  padding: 10px 20px;
  font-size: 16px;
  cursor: pointer;
}

.form {
  display: flex;
  flex-direction: column;
  align-items: center;
}

.label {
  margin: 10px 0;
}

.input {
  padding: 5px;
  font-size: 16px;
}

.submitButton {
  margin-top: 20px;
}

.css-fk8zu2-MuiButtonBase-root-MuiTab-root.Mui-selected{
  color: #86EFAC !important;
}
.css-11yukd5-MuiTabs-indicator{
  background-color:#86EFAC !important;
  padding: 1px !important;
  border-radius: 5px !important;
}
.css-6hp17o-MuiList-root-MuiMenu-list{
  background-color:#86EFAC !important;
}
.css-kk1bwy-MuiButtonBase-root-MuiMenuItem-root.Mui-selected{
  background-color: #fff !important;
}
.css-fk8zu2-MuiButtonBase-root-MuiTab-root{
  text-transform: none !important;
  font-size:1.1rem !important;
}

@keyframes shake {
  0% { transform: translate(0, 0); }
  10%, 30%, 50%, 70%, 90% { transform: translate(-10px, 0); }
  20%, 40%, 60%, 80%, 100% { transform: translate(10px, 0); }
}

.shaking-animation {
  animation: shake 0.5s infinite;
}